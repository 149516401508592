<template>
    <div class="content" v-loading="loading">
      <el-form ref="form" :model="form" label-position="left" label-width="80px">
        <el-form-item label="客户名称" >
          <el-input size="medium" v-model="form.customerName" :disabled="true"/>
        </el-form-item>
        <el-form-item label="客户邮箱">
          <el-input size="medium" v-model="form.email"/>
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input size="medium" v-model="form.phone" />
        </el-form-item>
        <el-form-item label="所属行业">
          <el-input size="medium" v-model="form.industryName" :disabled="true"/>
        </el-form-item>
        <el-form-item label="采集范围" class="range">
          <el-checkbox-group @change="selectChange"
            v-model="form.range">
            <el-checkbox v-for="item in checkboxList" :label="item" :key="item">{{item}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="终端词库" style="width: 91%">
          <el-input type="textarea" placeholder="请输入允许终端配置的监测主题关键词词库，用中文顿号【、】分隔" :rows="5  " v-model="form.keywordLib"></el-input>
        </el-form-item>
        <el-form-item label="自动报告">
          <el-switch
            v-model="form.isAutoDaily"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0">
          </el-switch>
          <span style="margin-left: 20px;color: slategray">自动报告开关是通过自定义模板自动生成报告的总开关</span>
<!--          <template v-if="form.isAutoDaily === '1'">-->
<!--            <a style="color: dodgerblue;margin-left: 20px" @click="setReport">详细设置</a>-->
<!--            <a style="color: dodgerblue;margin-left: 20px;" @click="setTemplate">日报模板设置</a>-->
<!--          </template>-->
        </el-form-item>
<!--        <el-form-item label="生成时间" v-if="form.isAutoDaily === '1'">-->
<!--          <el-time-select-->
<!--            placeholder="起始时间"-->
<!--            v-model="form.dailyTime"-->
<!--            value-format="HH:mm"-->
<!--            :picker-options="{-->
<!--              start: '00:00',-->
<!--              step: '00:60',-->
<!--              end: '23:00'-->
<!--            }">-->
<!--          </el-time-select>-->
<!--        </el-form-item>-->
        <el-form-item label="自动情报">
          <el-switch
            v-model="form.isAutoIntelligence"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0">
          </el-switch>
          <span style="margin-left: 20px;color: slategray">自动情报功能是将系统中监测到的负面数据自动加入到情报中心</span>
        </el-form-item>
        <!-- <el-form-item label="管理">
          <el-button type="text">监测主题</el-button>
          <el-button type="text">用户管理</el-button>
        </el-form-item> -->
        <el-form-item style="width: 100%;">
          <el-button  type="primary" size="medium" @click="saveCus">保存</el-button>
        </el-form-item>
      </el-form>
      <el-dialog title="模板设置" :visible.sync="showTemplateDialog" top="3%" width="80%" center>
        <dailyTemplate ref="template" @visible="getVisible"></dailyTemplate>
      </el-dialog>
    </div>

</template>

<script>
import eventHub from '@/utils/eventHub'
import dailyTemplate from '../../../perceptionReport/components/dailyTemplate.vue'
import { showProject, updateProjcet } from '@/api/settings/project'
export default {
  components: {
    dailyTemplate
  },
  data () {
    return ({
      loading: true,
      list: [{
        label: '系统设置',
        children: [{
          label: '项目设置'
        }]
      }],
      showTemplateDialog: false,
      form: {
        customerName: '',
        email: '',
        phone: '',
        range: [],
        isAutoDaily: '',
        dailyTime: '',
        isAutoIntelligence: '0',
        keywordLib: ''
      },
      checkboxList: ['网媒', '贴吧', '微信', '博客', '论坛', '电子报', '微博', '全网', '问答', '客户端', '视频']
    })
  },
  methods: {
    setTemplate () {
      this.showTemplateDialog = true
      // this.$refs.template.initializeSelect();
    },
    getVisible (data) {
      this.showTemplateDialog = data
      this.showReportDialog = data
    },
    setReport () {
      const { href } = this.$router.resolve({
        name: 'setAutoReport'
      })
      window.open(href, '_blank')
    },
    selectChange () {
      this.$forceUpdate()
    },
    async showProjectInfo () {
      const res = await showProject(this.form)
      this.form = res.data.res
      this.form.range = res.data.range
      this.form.isAutoDaily += ''
      this.form.isAutoIntelligence += ''
      this.loading = false
    },
    goBack () {
      this.$router.push('/settings/project/')
    },
    async update () {
      this.loading = true
      const res = await updateProjcet(this.form)
      this.loading = false
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.showProjectInfo()
      } else {
        this.$message.error(res.msg)
      }
    },
    saveCus () {
      this.update()
    }
  },
  mounted () {
    this.showProjectInfo()
    eventHub.$emit('msg', '项目设置')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';

  .content {
    width: 100%;
    // margin-left: 30px;
    border-radius: 2px;
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    padding-top: 50px;
    .el-form {
      display: flex;
      flex-wrap: wrap;
      padding: 0 30px;
      // padding-right: px;
      .el-form-item {
        width: 50%;
        &.range{
          width: 70%;
        }
        .el-input {
          width: 80%;
        }
        textarea {
          width: 80%;
          height: 136px;
          border: 1px solid #DCDFE6;
          color: #606266;
          border-radius: 4px;
        }
      }
    }
  }

</style>
